.product_tiles_column {
    padding-bottom: 30px;
    .product_tile {
        .product_tile_title {
            background-color: rgba(0, 0, 0, 0.05);
            h3 {
                color: @ms-red;
                font-size: 1.1em;
            }
        }
        .product_tile_description {
            background-color: rgba(0, 0, 0, 0.75);
            p {
                color: white;
            }
        }
    }
}
.product {
    .product_image {
        display: none;
    }
    .product_info {
        margin-left: 0;
        padding: 0;
    }
    .availability {
        display: none;
    }
}
table.cart_sum_content {
    tr.summary_net,
    tr.shipping {
        display: none;
    }
}
.buyable_update_cart {
    display: none;
}
.portlet {
    &.portletStaticText {
        .portletContent {
            ol{
                margin-left: 15px;
            }
            ul {
                margin-left: 15px;
                list-style: unset;
                padding: 15px;
            }
        }
    }
}
a#portal-logo {
    max-width: 500px;
    img {
        width: 100%;
    }
}
#form-checkout {
    .field {
        width: 100%;
    }
}
dl.details {
    margin-bottom: 10px;
}
a.buyable_add_to_cart {
    display: block;
    clear: both;
    float: left;
    width: 10em;
    margin-bottom: 2em;
}
div.product,
article.tileItem {
    clear: both;
}
div.buyable.viewlet dl.details {
    display: block;
}
a.product_link_more {
    display: none;
}
.tileBody {
    a {
        display: none;
    }
}
.searchSection {
    display: None;
}